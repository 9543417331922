


















































import { computed, defineComponent, reactive } from "@vue/composition-api";
import { AxiosInstance } from "axios";

interface FormError {
  error: string;
}

export default defineComponent({
  components: {
    OMessageAdd: () =>
      import("@/components/organisms/globalTools/message/o-message-add.vue"),
  },

  setup(_, { root }) {
    const model = reactive<{
      data: any;
    }>({
      data: {
        name: "",
        type: "registration",
        title: "Rejestracja na {{ event.name }}",
        template: "",
        design: {},
        editor: null,
      },
    });

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      dialog: false,
      errors: computed((): FormError[] => {
        const errors: FormError[] = [];

        if (!model.data.name) errors.push({ error: "Podaj nazwę szablonu." });

        return errors;
      }),
    });

    const isFormValid = computed(() => !state.errors.length);

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.data.name,
        template: model.data.editor?.html || undefined,
        type: model.data.type || undefined,
        title: model.data.title || undefined,
        design: model.data.editor.design || undefined,
      };

      state.loading = true;

      axiosInstance
        .post("message-template", data)
        .then(() => {
          state.success = true;
          state.dialog = true;
          model.data = {
            name: "",
            type: "registration",
            title: "",
            template: "",
            design: {},
            editor: null,
          };
        })
        .catch((error) => {
          state.error = error.response.status;
          state.dialog = true;
        })
        .finally(() => (state.loading = false));
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return "Sprawdź poprawność danych";
        case 401:
          return "Brak uprawnień";
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Szablon już istnieje";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    return {
      model,
      state,
      isFormValid,
      onSubmit,
      getErrorMessage,
    };
  },
});
